<template>
	<v-container class="background" fluid fill-height align-center>
		<v-row>
			<v-col xs="0" sm="0" md="1" lg="5" xl="7" />
			<v-col xl="5" lg="7" md="10" sm="12" xs="12">
				<v-card dark class="mx-auto">
					<v-row>
						<v-col align="center" class="px-5">
							<v-img
								:eager="true"
								contain
								src="/images/shootpro_logo_min.png"
								max-width="384"
								min-width="24"
								style="width: 100% !important"
							/>
							<br />

							<!-- <h1>Select A Club</h1> -->
							<!-- min-height="30" -->
						</v-col>
					</v-row>
					<v-card-text class="scroll parent">
						<div class="scroll child" id="list">
							<v-list class="backBleed mr-2" flat>
								<v-list-item-group>
									<v-list-item
										v-for="(choice, idx) in Shoots"
										:id="`s${idx}`"
										:key="`${choice.ClubId}${choice.ShootId}`"
										:class="SelectedItem == idx ? 'highlighted' : ''"
										@mouseover="SelectedItem = idx"
										@click="select(idx)"
									>
										<v-list-item-avatar color="primary">
											<v-icon v-show="SelectedItem == idx"> mdi-target </v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<h2 class="my-1 mb-2">
												{{ choice.ShootName }}
											</h2>
											<v-list-item-subtitle style="font-size: 13pt">
												<span>
													{{ choice.OrganizationName }}
												</span>
												<br />
												<span style="font-weight: 300">
													{{ choice.StartDate | date }} to
													{{ choice.EndDate | date }}
												</span>
											</v-list-item-subtitle>
										</v-list-item-content>

										<v-list-item-icon>
											<v-icon> mdi-chevron-right </v-icon>
										</v-list-item-icon>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="0" sm="0" md="1" v-if="$vuetify.breakpoint.mdAndDown" />
		</v-row>
	</v-container>
</template>

<script>
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
export default {
	filters: {
		date(value) {
			//Use dayjs to format day day week, month, day year

			return dayjs(value).format('dddd, MMM. Do YYYY');
		},
	},
	data: () => {
		return {
			SelectedItem: 0,
			Shoots: null,
			displayCard: false,
		};
	},
	beforeCreate() {
		dayjs.extend(advancedFormat);
	},
	async mounted() {
		this.$store.commit('shoot/RESET_SHOOT');

		let timeSpan = this.$route.query.timeSpan || 'current';

		try {
			let currentShoots = await this.$api.get(
				`/public/shoot?filter=${timeSpan}`
			);
			this.Shoots = currentShoots.data;
		} catch (err) {
			this.Shoots = [];
			if (err.response) {
				if (err.response.status === 404) {
					console.warn('No shoots found');
				} else {
					console.error(err.response.status, err.response.data);
				}
			} else {
				console.error(err);
			}
		}

		if (this.Shoots) {
			this.Shoots = this.Shoots.filter(
				(shoot) => !shoot.ShootName.includes('Demonstration')
			);
		}

		if (this.Shoots.length === 0) {
			try {
				this.Shoots = (await this.$api.get(`/public/shoot?filter=past3`)).data;
			} catch (err) {
				console.error('Error fetching past shoots:', err);
			}
		}

		setTimeout(() => {
			this.displayCard = true;
		}, 500);

		window.addEventListener('keydown', this.keyboardShortcuts);
		if (localStorage.useDev) {
			window.$snackbar('Dev Server Active See Console', 'grey', 2000);
			console.warn(
				`YOU ARE IN DEVMODE GOTO ${
					window.location.href + '?useDev=false'
				} to disable`
			);
		}
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
	methods: {
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'ArrowLeft':
				case 'ArrowUp':
					if (this.SelectedItem == 0) this.SelectedItem = this.Shoots.length;
					this.SelectedItem--;
					break;
				case 'ArrowRight':
				case 'ArrowDown':
					if (this.SelectedItem == this.Shoots.length - 1)
						this.SelectedItem = -1;
					this.SelectedItem++;
					break;
				case 'Enter':
					this.select();
					break;
			}

			this.$vuetify.goTo(`#s${this.SelectedItem}`, { container: '#list' });
		},
		select(idx = undefined) {
			if (idx) this.SelectedItem = idx;
			const so = this.Shoots[this.SelectedItem];
			let o = {
				ClubId: so.ClubId,
				ClubName: so.ClubName,
				AssociationId: so.AssociationId,
				Address1: so.Address1,
				Address2: so.Address2,
				City: so.City,
				State: so.State,
				Zip: so.Zip,
				Phone: so.Phone,
				Email: so.Email,
				TimeZone: so.TimeZone,
				ScoreboardSettings: so.ScoreboardSettings,
			};

			this.$store.dispatch('shoot/LoadShoot', {
				club: o,
				shoot: so.ShootId,
			});
			this.$nextTick(() => {
				this.$router.push('/');
			});
		},
	},
};
</script>

<style scoped>
.highlighted {
	background: #24598c;
}
.backBleed {
	background: rgba(30, 30, 30, 0);
}
.background {
	background-image: url('/images/back/background.webp'),
		url('/images/back/background-med.webp'),
		url('/images/back/background-ultra-low.webp');
	background-size: cover, cover, cover;
	background-repeat: no-repeat, no-repeat, no-repeat;
}

.scroll.parent {
	height: calc(80vh) !important;
	overflow-y: hidden;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}
.scroll.child {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 7px;
	height: 18px;
}

::-webkit-scrollbar-thumb {
	height: 6px;
	border: 0px solid #24598c;
	background-clip: padding-box;
	background-color: #24598c;
	-webkit-border-radius: 7px;
	-webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05),
		inset 1px 1px 0px rgba(255, 255, 255, 0.05);
}

::-webkit-scrollbar-button {
	display: none;
	width: 0;
	height: 0;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}
</style>
